import { b } from "@/b";
import { HEADER_FRAGMENT, Header } from "@/components/Header";
import { PageLayout } from "@/components/PageLayout";
import { Hero } from "@/components/layout/Hero";
import { Section } from "@/components/layout/Section";
import { getApolloSSRProps, useHydratedQuery } from "@/util/apollo-client";
import {
  GetServerSideProps,
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
  NextPage,
} from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import HOME_HERO from "@/img/home_hero.png";
import {
  MealTagShowcase,
  MealTagShowcase_Fragment,
} from "@/components/pages/home/MealTagShowcase";
import { EasyToOrderSection } from "@/components/pages/home/EasyToOrder";
import {
  ChefSpecialSection,
  ChefSpecials_Fragment,
} from "@/components/pages/home/ChefSpecials";
import { AboutUsBrief } from "@/components/pages/home/AboutUsBrief";
import { AffiliateSignUpSection } from "@/components/pages/home/AffiliateSignUp";
import { popupMiddleware } from "@/sanity/popup-middleware";

const HOME_QUERY = b.query("Home", (b) => [
  //
  b.__fragment(HEADER_FRAGMENT),
  b.__fragment(MealTagShowcase_Fragment),
  b.__fragment(ChefSpecials_Fragment),
]);

const Home: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = (
  props
) => {
  const router = useRouter();
  const { data } = useHydratedQuery(props);

  return (
    <>
      <Head>
        <title>Visionary Meals</title>
        <meta
          name="description"
          content="Explore our delicious gluten-free, farm to table, fully-prepped meals!"
        />
      </Head>
      <PageLayout data={data!} noPadding>
        <Section noTopPadding>
          <Hero
            header="BE A VISIONARY"
            tagline="Freshly-cooked meals delivered to your nearest pickup location or straight to your door"
            buttons={[
              {
                label: "View Menu",
                onClick: () => {
                  router.push("/menu");
                },
              },
              {
                label: "Nutrition Coaching",
                onClick: () => {
                  router.push("/nutrition");
                },
              },
            ]}
            background={HOME_HERO}
            lazyLoadBackground={false}
            container={{
              __css: {
                "& > img": {
                  objectPosition: "bottom",
                },
              },
              backgroundPosition: "bottom",
              backgroundSize: "cover",
              minHeight: {
                base: "80vh",
                md: "50vh",
              },
              pt: "24",
            }}
          />
        </Section>
        <MealTagShowcase data={data} />
        <EasyToOrderSection />
        <ChefSpecialSection data={data} />
        <AboutUsBrief />
        <AffiliateSignUpSection />
      </PageLayout>
    </>
  );
};

export default Home;

export const getServerSideProps = popupMiddleware(
  async (ctx: GetServerSidePropsContext) => {
    return {
      props: await getApolloSSRProps(HOME_QUERY.document(), {}, ctx),
    };
  }
);
